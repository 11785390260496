const vacanciesCards = () => {
    const vacanciesContainer = document.querySelector('.vacancies-cards .cards-container');
    const internshipsContainer = document.querySelector('.internship-cards .cards-container');
    let vacancyArr = [];
    let internshipArr = [];

    // Загрузка и обработка JSON файла
    fetch('vacancy.json')
        .then(response => response.json())
        .then(data => {
            vacancyArr = [];
            internshipArr = [];

            data.forEach(item => {
                if (item.type === 'vacancy') {
                    vacancyArr.push(item);
                } else if (item.type === 'internship') {
                    internshipArr.push(item);
                }
            });

            renderCards(vacancyArr, vacanciesContainer);
            renderCards(internshipArr, internshipsContainer);
        })
        .catch(error => console.error('Ошибка загрузки данных:', error));

    function renderCards(arr, container) {
        container.innerHTML = ''; // Очистка контейнера

        arr.length === 0
        ? container.innerHTML = '<div class="cards-not-found">К сожалению, в настоящее время предложения данной категории закончились :(</div>'
        :arr.forEach((item, index) => {
            const card = document.createElement('div');
            card.classList.add('card-item');
            card.innerHTML = `
                <div class="category-wrapper"><div class="category">${item.category}</div></div>
                <h3 class="job-title">${item.jobTitle}</h3>
                <p class="company">${item.company}</p>
                <p class="salary">${item.salary}</p>
                <div style='text-align:center; width:100%'><a href="https://vk.com/im?sel=-226428794" class="details-button base-button" target="_blank">подробнее</a></div>
            `;
            container.appendChild(card);

            // Анимация фейда
            setTimeout(() => {
                card.classList.add('fade-in');
            }, 1);
        });
    }

    function filterVacancies() {
        const selectedFilters = Array.from(document.querySelectorAll('.vacancies-cards .filter-button ~ .dropdown-menu input[type="checkbox"]:checked')).map(input => input.value);
        const filteredVacancies = selectedFilters.length === 0
            ? vacancyArr
            : vacancyArr.filter(item => selectedFilters.includes(item.filterValue));

        renderFadeOutAndIn(filteredVacancies, vacanciesContainer);
    }

    function filterInternships() {
        const selectedFilters = Array.from(document.querySelectorAll('.internship-cards .filter-button ~ .dropdown-menu input[type="checkbox"]:checked')).map(input => input.value);
        const filteredInternships = selectedFilters.length === 0
            ? internshipArr
            : internshipArr.filter(item => selectedFilters.includes(item.filterValue));

        renderFadeOutAndIn(filteredInternships, internshipsContainer);
    }

    function parseSalary(salary) {
        let salaryRange = salary.split('-').map(part => part.trim());

        let salaryMin = parseFloat(salaryRange[0].replace(/[^\d.]/g, ''));
        let salaryMax = salaryRange[1] ? parseFloat(salaryRange[1].replace(/[^\d.]/g, '')) : salaryMin;

        return (salaryMin + salaryMax) / 2;
    }

    function sortVacancies() {
        const selectedSort = document.querySelector('.vacancies-cards .sort-button ~ .dropdown-menu input[type="radio"]:checked').value;

        let sortedVacancies = [...vacancyArr];

        if (selectedSort === 'salary_asc') {
            sortedVacancies.sort((a, b) => parseSalary(a.salary) - parseSalary(b.salary));
        } else if (selectedSort === 'salary_desc') {
            sortedVacancies.sort((a, b) => parseSalary(b.salary) - parseSalary(a.salary));
        } else if (selectedSort === 'date_asc') {
            sortedVacancies.sort((a, b) => new Date(a.date) - new Date(b.date));
        } else if (selectedSort === 'date_desc') {
            sortedVacancies.sort((a, b) => new Date(b.date) - new Date(a.date));
        }

        renderFadeOutAndIn(sortedVacancies, vacanciesContainer);
    }

    function sortInternships() {
        const selectedSort = document.querySelector('.internship-cards .sort-button ~ .dropdown-menu input[type="radio"]:checked').value;

        let sortedInternships = [...internshipArr];

        if (selectedSort === 'salary_asc') {
            sortedInternships.sort((a, b) => parseSalary(a.salary) - parseSalary(b.salary));
        } else if (selectedSort === 'salary_desc') {
            sortedInternships.sort((a, b) => parseSalary(b.salary) - parseSalary(a.salary));
        } else if (selectedSort === 'date_asc') {
            sortedInternships.sort((a, b) => new Date(a.date) - new Date(b.date));
        } else if (selectedSort === 'date_desc') {
            sortedInternships.sort((a, b) => new Date(b.date) - new Date(a.date));
        }

        renderFadeOutAndIn(sortedInternships, internshipsContainer);
    }

    function renderFadeOutAndIn(arr, container) {
        const cards = container.querySelectorAll('.card-item');
        let fadeOutCompleted = 0;

        function checkFadeOutComplete() {
            fadeOutCompleted++;
            if (fadeOutCompleted === cards.length) {
                renderCards(arr, container);
            }
        }

        if (cards.length === 0) {
            renderCards(arr, container);
        } else {
            cards.forEach((card) => {
                card.classList.add('fade-out');
                card.addEventListener('transitionend', () => {
                    checkFadeOutComplete();
                });
            });
        }
    }

    document.querySelectorAll('.vacancies-cards .filter-button ~ .dropdown-menu input[type="checkbox"]').forEach(checkbox => {
        checkbox.addEventListener('change', filterVacancies);
    });

    document.querySelectorAll('.internship-cards .filter-button ~ .dropdown-menu input[type="checkbox"]').forEach(checkbox => {
        checkbox.addEventListener('change', filterInternships);
    });

    document.querySelectorAll('.vacancies-cards .sort-button ~ .dropdown-menu input[type="radio"]').forEach(radio => {
        radio.addEventListener('change', sortVacancies);
    });

    document.querySelectorAll('.internship-cards .sort-button ~ .dropdown-menu input[type="radio"]').forEach(radio => {
        radio.addEventListener('change', sortInternships);
    });

    function setupDropdowns(container) {
        const sortButton = container.querySelector('.sort-button');
        const filterButton = container.querySelector('.filter-button');
        const sortDropdown = sortButton.closest('.dropdown');
        const filterDropdown = filterButton.closest('.dropdown');

        sortButton.addEventListener('click', function (e) {
            sortDropdown.classList.toggle('active');
            filterDropdown.classList.remove('active');

            if (e.target.classList.contains('sort-button')) {
                e.target.classList.toggle('menu-active')
            }
            
            if (e.target.classList.contains('fa-sort')) {
                e.target.closest('button').classList.toggle('menu-active')
            }
        });

        filterButton.addEventListener('click', function (e) {
            filterDropdown.classList.toggle('active');
            sortDropdown.classList.remove('active');

            if (e.target.classList.contains('filter-button')) {
                e.target.classList.toggle('menu-active')
            }

            if (e.target.classList.contains('fa-filter')) {
                e.target.closest('button').classList.toggle('menu-active')
            }
        });

        document.addEventListener('click', function (e) {
            if (!sortDropdown.contains(e.target) && !sortButton.contains(e.target)) {
                sortDropdown.classList.remove('active');
                sortButton.classList.remove('menu-active');
            }
            if (!filterDropdown.contains(e.target) && !filterButton.contains(e.target)) {
                filterDropdown.classList.remove('active');
                filterButton.classList.remove('menu-active');
            }
        });
    }

    setupDropdowns(document.querySelector('.vacancies-cards'));
    setupDropdowns(document.querySelector('.internship-cards'));
}

export default vacanciesCards;