const scrollUp = () => {

    const scrollToTopButton = document.querySelector('.scroll-to-top');

    window.addEventListener('scroll', () => {
        if (window.scrollY > 300) {
            scrollToTopButton.classList.add('show-scroll-to-top');
        } else {
            scrollToTopButton.classList.remove('show-scroll-to-top');
        }
    });

    scrollToTopButton.addEventListener('click', (event) => {
        event.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    });
}

export default scrollUp;