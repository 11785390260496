import promoSlider from "./modules/promoSlider";
import ourTeamSlider from "./modules/ourTeamSlider";
import vacanciesCards from "./modules/vacanciesCards";
import careerConsultation from "./modules/careerConsultation";
import smoothScroll from "./modules/smoothScroll";
import scrollUp from "./modules/scrollUp";

document.addEventListener('DOMContentLoaded', function () {
    'use strict';

    smoothScroll();
    promoSlider();
    ourTeamSlider();

    vacanciesCards();

    careerConsultation();
    scrollUp();
});