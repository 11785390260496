const careerConsultation = () => {
    const buttons = document.querySelectorAll('.consultation-button');
    const contentItems = document.querySelectorAll('.content-item');
    const signUpButton = document.querySelector('.sign-up-button'); // находим кнопку "записаться"

    // Данные для кнопки "составление резюме"
    const resumeLinkData = {
        text: 'составить резюме',
        url: 'https://forms.yandex.ru/cloud/66d8163c2530c23a2845c296/'
    };

    // Исходные данные для кнопки "записаться"
    const defaultLinkData = {
        text: 'записаться',
        url: 'https://forms.yandex.ru/cloud/66d819b2068ff0ccea9c2954/'
    };

    // Функция для смены ссылки внизу
    const updateSignUpButton = (contentId) => {
        if (contentId === 'resume') {
            signUpButton.querySelector('div').textContent = resumeLinkData.text; // меняем текст кнопки
            signUpButton.setAttribute('href', resumeLinkData.url); // меняем ссылку
        } else {
            // Возвращаем кнопку в исходное состояние
            signUpButton.querySelector('div').textContent = defaultLinkData.text;
            signUpButton.setAttribute('href', defaultLinkData.url);
        }
    };

    // Устанавливаем начальное состояние для кнопки (составление резюме по умолчанию)
    updateSignUpButton('resume');

    buttons.forEach(button => {
        button.addEventListener('click', function () {
            // Удаляем класс активности со всех кнопок
            buttons.forEach(btn => btn.classList.remove('active'));
            // Добавляем класс активности к нажатой кнопке
            this.classList.add('active');

            // Скрываем все контентные блоки
            contentItems.forEach(item => item.style.display = 'none');
            // Отображаем контентный блок, соответствующий нажатой кнопке
            const contentId = this.getAttribute('data-content');
            document.getElementById(contentId).style.display = 'block';

            // Обновляем кнопку внизу в зависимости от выбранного контента
            updateSignUpButton(contentId);
        });
    });
}

export default careerConsultation;
