const ourTeamSlider = () => {
    const slides = document.querySelectorAll('.team-slide');
    const arrowLeft = document.querySelector('.team-arrow-left');
    const arrowRight = document.querySelector('.team-arrow-right');
    const totalSlides = slides.length;
    let currentIndex = 0;

    function updateSlides() {
        slides.forEach((slide, index) => {
            slide.classList.remove('active');

            if (index === currentIndex) {
                slide.classList.add('active');
                slide.style.transform = 'translateX(0%) scale(1)';
                slide.style.opacity = '1';
            } else if (index === (currentIndex - 1 + totalSlides) % totalSlides) {
                slide.style.transform = 'translateX(-110%) scale(0.8)';
                slide.style.opacity = '0.5';
            } else if (index === (currentIndex + 1) % totalSlides) {
                slide.style.transform = 'translateX(110%) scale(0.8)';
                slide.style.opacity = '0.5';
            } else {
                slide.style.transform = 'translateX(220%) scale(0.8)';
                slide.style.opacity = '0';
            }
        });
    }

    function showNextSlide() {
        currentIndex = (currentIndex + 1) % totalSlides;
        updateSlides();
    }

    function showPrevSlide() {
        currentIndex = (currentIndex - 1 + totalSlides) % totalSlides;
        updateSlides();
    }

    arrowRight.addEventListener('click', showNextSlide);
    arrowLeft.addEventListener('click', showPrevSlide);

    let startX = 0;
    let endX = 0;

    document.querySelector('.team-slider-wrapper').addEventListener('touchstart', function (e) {
        startX = e.touches[0].clientX;
    });

    document.querySelector('.team-slider-wrapper').addEventListener('touchmove', function (e) {
        endX = e.touches[0].clientX;
    });

    document.querySelector('.team-slider-wrapper').addEventListener('touchend', function () {
        if (startX > endX) {
            showNextSlide();
        } else {
            showPrevSlide();
        }
    });

    // Initial setup
    updateSlides();
}

export default ourTeamSlider;