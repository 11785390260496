const promoSlider = () => {

    const slides = document.querySelectorAll('.slide');
        const dots = document.querySelectorAll('.dot');
        const sliderContainer = document.querySelector('.slider-container');
        let currentIndex = 0;
        const slideInterval = 200000;
        let slideTimer;
    
        const goToSlide = (index) => {
            currentIndex = index;
            const offset = -100 * currentIndex;
            sliderContainer.style.transform = `translateX(${offset / slides.length}%)`;
            updateDots();
        };
    
        const updateDots = () => {
            dots.forEach((dot, index) => {
                dot.classList.toggle('active', index === currentIndex);
            });
        };
    
        const nextSlide = () => {
            currentIndex = (currentIndex + 1) % slides.length;
            goToSlide(currentIndex);
        };
    
        slideTimer = setInterval(nextSlide, slideInterval);
    
        let isDragging = false;
        let startPos = 0;
        let currentTranslate = 0;
        let prevTranslate = 0;
        let animationID = 0;
        let currentPosition = 0;
    
        const getPositionX = (event) => event.type.includes('mouse') ? event.pageX : event.touches[0].clientX;
    
        const animation = () => {
            setSliderPosition();
            if (isDragging) requestAnimationFrame(animation);
        };
    
        const setSliderPosition = () => {
            sliderContainer.style.transform = `translateX(${currentTranslate}px)`;
        };
    
        const handleTouchStart = (event) => {
            startPos = getPositionX(event);
            isDragging = true;
            animationID = requestAnimationFrame(animation);
            sliderContainer.classList.add('grabbing');
            clearInterval(slideTimer);
        };
    
        const handleTouchMove = (event) => {
            if (isDragging) {
                currentPosition = getPositionX(event);
                currentTranslate = prevTranslate + currentPosition - startPos;
    
                // Prevent swiping beyond the first and last slides
                if (currentTranslate > 0) {
                    currentTranslate = 0;
                }
                if (currentTranslate < -sliderContainer.clientWidth + sliderContainer.clientWidth / slides.length) {
                    currentTranslate = -sliderContainer.clientWidth + sliderContainer.clientWidth / slides.length;
                }
            }
        };
    
        const handleTouchEnd = () => {
            isDragging = false;
            cancelAnimationFrame(animationID);
            const movedBy = currentTranslate - prevTranslate;
    
            if (movedBy < -20 && currentIndex < slides.length - 1) {
                currentIndex += 1;
            }
    
            if (movedBy > 20 && currentIndex > 0) {
                currentIndex -= 1;
            }
    
            setPositionByIndex();
            sliderContainer.classList.remove('grabbing');
            slideTimer = setInterval(nextSlide, slideInterval);
        };
    
        const setPositionByIndex = () => {
            currentTranslate = currentIndex * -sliderContainer.clientWidth / 3; // Divide by 3 to match the 33.33% width of each slide
            prevTranslate = currentTranslate;
            setSliderPosition();
            updateDots();
        };
    
        sliderContainer.addEventListener('mousedown', handleTouchStart);
        sliderContainer.addEventListener('mousemove', handleTouchMove);
        sliderContainer.addEventListener('mouseup', handleTouchEnd);
        sliderContainer.addEventListener('mouseleave', handleTouchEnd);
    
        sliderContainer.addEventListener('touchstart', handleTouchStart);
        sliderContainer.addEventListener('touchmove', handleTouchMove);
        sliderContainer.addEventListener('touchend', handleTouchEnd);
    
        dots.forEach((dot, index) => {
            dot.addEventListener('click', () => {
                goToSlide(index);
                clearInterval(slideTimer);
                slideTimer = setInterval(nextSlide, slideInterval);
            });
        });
    
        updateDots();
        setPositionByIndex();

}

export default promoSlider;